<template>
  <el-dialog
    v-model="dialogVisible"
    title="Waring"
    width="544px"
    :modal="true"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    :append-to-body="true"
    class="vpnWarningDialog"
    modal-class="vpn-modal-dialog"
  >
    <div class="header flex-row flex-row-space-between">
      <div class="icon-warning" />
    </div>
    <!-- 信息确认提醒及VPN理由填写 -->
    <template v-if="type == 1">
      <p class="name text-lg-sb gray-900 mb16 mt16">
        {{ $t('信息确认提醒及VPN理由填写') }}
      </p>
      <p class="text-sm-r gray-600 mb16">
        <span>{{ $t('抱歉，我们监测到您或许在使用VPN网络服务（当前IP：%s1），我们需要和您进行情况核实，请您在 ').replace('%s1', userInfo.ip) }}</span>
        <span class="text-sm-m warning-700">
          {{ $t('%s1天内（%s2）').replace('%s1', userInfo.vpnRemainDay).replace('%s2', `${moment().add(userInfo.vpnRemainDay, 'd').format('YYYY/MM/DD')} 23:59:59`) }}
        </span>
        <span>{{ $t('确认下述信息是否仍然有效：') }}</span>
      </p>
      <div class="text-sm-r gray-900 mb16">
        <p class="mb3">
          {{ `${$t('姓名：')}${userInfo.firstName}${userInfo.lastName}` }}
        </p>
        <p class="mb3">
          {{ `${$t('身份信息：')}${userInfo.certId}` }}
        </p>
        <p class="mb3">
          {{ `${$t('国籍：')}${userInfo.nationalityName}` }}
        </p>
        <p>
          {{ `${$t('常居地：')}${userInfo.residentialAddress}` }}
        </p>
      </div>
      <div class="mb16">
        <p class="text-sm-m gray-700 mb6">
          {{ $t('目前使用VPN的理由') }}
        </p>
        <el-select
          v-model="vpnWarningForm.reason"
          class="w-full h42"
          popper-class="vpn-warning-select-popper"
        >
          <el-option
            v-for="item in userInfo.vpnReasonList"
            :key="item.code"
            :label="item.desc"
            :value="item.code"
          />
        </el-select>
        <p class="text-sm-r gray-600 mt8">
          {{ $t('如有其他使用理由，请联系%s1进行更新。').replace('%s1', 'support@customer.hashkey.com') }}
        </p>
      </div>
      <p class="text-sm-r gray-900 mb16">
        {{ $t('我司在此郑重提醒，若您持续长期无合理理由使用VPN，或导致账户封禁，根据相应法规要求我司或无法继续为您/贵机构提供的服务。') }}
      </p>
      <el-checkbox
        v-model="vpnWarningForm.check"
        class="warning-check"
      >
        {{ $t('确认是本人行为，以上理由真实有效，不影响KYC中的信息，上述内容已阅读并知晓') }}
      </el-checkbox>
      <div class="mt32 flex-row flex-row-flex-end">
        <hk-button 
          class="false-sg-md gray-700 h42"
          @click="handleClose"
        >
          {{ $t('关闭') }}
        </hk-button>
        <hk-button
          class="false-primary-md base-white h42 ml12"
          :disabled="!vpnWarningForm.reason || !vpnWarningForm.check"
          @click="confirm"
        >
          {{ $t('确认') }}
        </hk-button>
      </div>
    </template>
    <!-- 解封提醒 -->
    <template v-if="type == 2">
      <p class="name text-lg-sb gray-900 mb16 mt16">
        {{ $t('解封提醒') }}
      </p>
      <p class="text-sm-r gray-600 mb20">
        {{ $t('抱歉，我们检测到您已违规长期使用VPN（当前IP：%s1），我们目前已暂时封禁您的账户，您将无法正常访问账户完成交易、充值提现等功能，为完成账户解封，您需要填写下述信息：').replace('%s1', userInfo.ip) }}
      </p>
      <div class="text-sm-r gray-900 mb20">
        <p class="mb16 h32 flex-row items-center">
        <div class="flex1">
          {{ $t('姓名') }}<br>
          <span class="text-xs-r gray-500 mt4">{{ $t('请您填写姓名的最后一个字') }}</span>
        </div>
        <div class="w200 flex-row-flex-end flex-row items-center">
          {{ userInfo.firstName }}
          <el-input 
            v-model="vpnWarningForm.lastName"
            class="w68 h32 ml8"
          />
        </div>
        </p>
        <p class="mb16 h32 flex-row items-center">
        <div class="flex1">
          {{ $t('身份信息') }}<br>
          <span class="text-xs-r gray-500 mt4">{{ $t('请您填写证件号的最后两位') }}</span>
        </div>
        <div class="w200 flex-row-flex-end flex-row items-center">
          {{ userInfo.certId }}
          <el-input 
            v-model="vpnWarningForm.certId_1"
            class="w32 h32 ml4"
            maxlength="1"
          />
          <el-input 
            v-model="vpnWarningForm.certId_2" 
            class="w32 h32 ml4"
            maxlength="1"
          />
        </div>
        </p>
        <p class="mb16 flex-row items-center">
        <div class="flex1">
          {{ $t('目前使用VPN的理由') }}<br>
          <span class="text-xs-r gray-500 mt4">{{ $t('如有其他使用理由，请联系%s1进行更新。').replace('%s1', 'support@customer.hashkey.com') }}</span>
        </div>
        <div class="w200 flex-row-flex-end flex-row items-center ml10">
          <el-select
            v-model="vpnWarningForm.reason"
            class="w-full h42"
            popper-class="w200 vpn-warning-select-popper"
          >
            <el-option
              v-for="item in userInfo.vpnReasonList"
              :key="item.code"
              :label="item.desc"
              :value="item.code"
            />
          </el-select>
        </div>
        </p>
        
        <p class="mb16 flex-row items-center">
        <div class="flex1">
          {{ $t('国籍') }}<br>
        </div>
        <div class="flex1 flex-row-flex-end">
          {{ userInfo.nationalityName }}
        </div>
        </p>
        <p class="flex-row items-center">
        <div class="flex1">
          {{ $t('常居地') }}<br>
        </div>
        <div class="flex1 flex-row-flex-end">
          {{ userInfo.residentialAddress }}
        </div>
        </p>
        <p v-if="errMsg" class="mt16 text-xs-r error-700">
          {{ errMsg }}
        </p>
      </div>
      <p class="text-sm-r gray-900 mb16">
        {{ $t('我司在此郑重提醒，若您持续长期无合理理由使用VPN，或导致账户封禁，根据相应法规要求我司或无法继续为您/贵机构提供的服务。') }}
      </p>
      <div class="mt32 flex-row flex-row-flex-end">
        <hk-button 
          class="false-sg-md gray-700 h42"
          @click="logout"
        >
          {{ $t('退出登录') }}
        </hk-button>
        <hk-button
          class="false-primary-md base-white h42 ml12"
          :disabled="!vpnWarningForm.lastName || !vpnWarningForm.certId_1 || !vpnWarningForm.certId_2 || !vpnWarningForm.reason"
          @click="confirm"
        >
          {{ $t('确认') }}
        </hk-button>
      </div>
    </template>
    <!-- 您的账户已暂时封禁 -->
    <template v-if="type == 3">
      <p class="name text-lg-sb gray-900 mb16 mt16">
        {{ $t('您的账户已暂时封禁') }}
      </p>
      <p class="text-sm-r gray-600 mb16">
        {{ $t('抱歉，我们检测到您已违规长期使用VPN（当前IP：%s1），我们目前已暂时封禁您的账户，您将无法正常访问账户完成交易、充值提现等功能。').replace('%s1', userInfo.ip) }}
      </p>
      <p class="text-sm-r gray-600 mb16">
        {{ $t('您可以发送以下材料至%s1，来完成身份证明及VPN使用理由说明，尽快完成账户解封。').replace('%s1', 'support@customer.hashkey.com') }}
      </p>
      <ul class="text-sm-r gray-900 mb16">
        <li>{{ $t('身份证明材料') }}</li>
        <li>{{ $t('VPN使用理由证明：机票材料等') }}</li>
      </ul>
      <p class="text-sm-r gray-600">
        {{ $t('为保障您的权益，请尽快完成操作。如有任何问题，请联系我们的在线客服。') }}
      </p>
      <div class="mt32 flex-row flex-row-flex-end">
        <hk-button
          class="false-primary-md base-white h42"
          @click="logout"
        >
          {{ $t('退出登录') }}
        </hk-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useUserStore } from '@/store/User';
import { ucApi } from '@/config/api';
import { toast } from '@/utils/toast';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';
const { t } = useI18n();
const userStore = useUserStore();
const emits = defineEmits(['up-visible']);
const props = defineProps({
  visible: Boolean,
  type: {
    type: Number,
    default: 1 // 1->不需要校验姓名和身份信息，2->需要校验
  },
  vpnBlockInfo: {
    type: Object,
    default: () => {
      return {};
    }
  }
});
// 报错信息
const errMsg = ref('');
// // vpn理由选择列表
// const vpnReasonList = ref([
//   {
//     code: 1,
//     desc: t('短期旅游'),
//   },
//   {
//     code: 2,
//     desc: t('短期求学'),
//   },
//   {
//     code: 3,
//     desc: t('短期探亲'),
//   },
//   {
//     code: 4,
//     desc: t('其他短期合理理由'),
//   }
// ]);
// 表单信息
const vpnWarningForm = ref({
  lastName: '',
  certId_1: '',
  certId_2: '',
  reason: '',
  check: false
});
// 弹窗
const dialogVisible = computed(() => {
  return props.visible;
});
// 查询信息
const userInfo = computed(() => {
  return props.vpnBlockInfo;
});
// 关闭弹窗
const handleClose = () => {
  userStore.changeVpnWarningInfo({ visible: false });
  emits('up-visible', false);
};
// 确认
const confirm = () => {
  let params = {
    lastName: vpnWarningForm.value.lastName,
    certId: `${userInfo?.value?.certId}${vpnWarningForm.value.certId_1}${vpnWarningForm.value.certId_2}`,
    reason: vpnWarningForm.value.reason,
    type: props.type
  };
  ucApi.vpnDeBlock(params)
    .then((data:any) => {
      if (data.code === 200) {
        toast.success(t('提交成功'));
        userStore.changeVpnWarningInfo({ visible: false });
        emits('up-visible', false);
      } else {
        // toast.error(t(data.message));
        errMsg.value = data.message;
      }
    });
};
// 登出
const logout = () => {
  userStore.changeVpnWarningInfo({ visible: false });
  userStore.logOut();
};
</script>
  <style lang="scss">
  .vpn-modal-dialog {
    z-index: 99998 !important;
  }
  .vpn-warning-select-popper {
    z-index: 99999 !important;
  }
  .vpnWarningDialog {
    border-radius: 12px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__footer {
      display: none;
    }
    .el-dialog__body {
      border-radius: 12px;
      background: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
      padding: 24px;
      .icon-warning {
        width: 48px;
        height: 48px;
        background: url(@/assets/img/common/icon-warning.svg) center/cover no-repeat;
      }
      .warning-check {
        width: 100%;
        white-space: normal;
        align-items: flex-start;
        color: var(--gray-900);
      }
      li {
        position: relative;
        padding-left: 20px;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: var(--gray-900);
          left: 8px;
          top: 8px;
        }
      }
    }
    .el-input__inner {
      color: var(--gray-900);
    }
    
    .el-input__wrapper {
      border-radius: 8px;
      border: 1px solid var(--gray-300);
      box-shadow: none;

      &.is-focus,
      &:hover {
        box-shadow: 0 0 0 1px var(--gray-700) inset;
      }
    }
  }
  </style>
