/**
 * @file 统一操作 API
 *
 * @example
 *
 *  - import { $api } from '@/utils/api';
 *  - $api.getDemo
 */
import axios from '@/utils/axios';
import { type KYC_COUNTRY_CONFIG_NAME } from './const';

export const kycApi = {
  // 获取用户KYC级别信息
  getUserKycInfo() {
    return axios.get('@bapi/v3/kyc/common/status');
  },
  // 更新用户类型
  upUserType(data: object) {
    return axios.post('@bapi/v3/kyc/common/save/user_type', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 查询证件类型
  getAddressType(params: object) {
    return axios.get('@bapi/v3/kyc/common/address_proof/config', { params });
  },
  // 更新用户类型2
  upContinueUserType(data: object) {
    return axios.post('@bapi//v3/kyc/common/save/user_type_continue', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 保存kyc材料
  saveKycMaterial(data: object) {
    return axios.post('@bapi/v3/kyc/common/save/kyc_material', data, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });
  },
  // 查询kyc材料
  getKycMaterial(params: object) {
    return axios.get('@bapi/v3/kyc/common/query/kyc_material', { params });
  },
  // 获取投资经验问卷
  getRisk() {
    return axios.get('@bapi/v3/kyc/exam/get/risk');
  },
  // 获取投资经验问卷
  getKnowledge() {
    return axios.get('@bapi/v3/kyc/exam/get/knowledge');
  },
  // 提交投资经验/虚拟货币知识问卷答案
  submitExam(data: object) {
    return axios.post('@bapi/v3/kyc/exam/submit', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 提交投资经验等级
  submitRiskLevel(data: object) {
    return axios.post('@bapi/v3/kyc/exam/risk/apply', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 查询风险状态和测评次数@吴晖
  getRiskStatus() {
    return axios.get('@bapi/v3/kyc/exam/risk/status');
  },

  // 提交kyc申请
  submitKycApply(data: object) {
    return axios.post('@bapi/v3/kyc/common/submit/kyc_material', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 线下提交kyc申请
  submitOfflineKycApply(data: object) {
    return axios.post('@bapi/v3/kyc/common/submit/kyc_material/offline', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 提交hashkey Custody
  submitCustodyApply(data: object) {
    return axios.post('@bapi/v3/kyc/common/submit/custody/apply', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // Jumio
  resetJumio(data: object) {
    return axios.post('@bapi/v3/kyc/init/auth', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // Jumio
  resetCertJumio(data: object) {
    return axios.post('@bapi/v3/kyc/init/cert', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // Jumio fastfill
  resetJumioFastfill(data: object) {
    return axios.post('@bapi/v3/kyc/init/fastfill', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // Jumio 人脸识别
  resetJumioLiveness(data: object) {
    return axios.post('@bapi/v3/kyc/init/liveness', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取税务信息页面的下拉列表
  getList(params: object) {
    return axios.get('@bapi/v3/kyc/common/reference', { params });
  },
  // 下载授权文件
  getDownloadFile(params: object) {
    return axios.get('@api/v3/file/download-file',  { params });
  },
  // 预览文件
  getPreviewFile(params: object) {
    return axios.get('@api/v3/kyc/file/preview/url',  { params });
  },
  // KYC机构客户资料提交
  customerSubmit(data: object) {
    return axios.post('@bapi/v3/kyc/common/customer/submit', data);
  },
  // 查询高风险拦截规则
  getInterceptorRules() {
    return axios.get('@bapi/v3/kyc/common/interceptor/rules');
  },
  // 查询当前用户的风控限额信息
  getBatch() {
    return axios.get('@bapi/v3/kyc/common/risk_limit/query');
  },
  // KYC银行认证配置
  getAssetKycToken() {
    return axios.get('@api/exchange/api/cfs/fiat/asset/kycTokenConfig');
  },
  // KYC提交审核返回审核中message
  getSubmitMessage() {
    return axios.get('@bapi/v3/kyc/common/submit/message');
  },
  // 渣打众安开关
  getFiatSwitch() {
    return axios.get('@api/exchange/api/cfs/fiat/account/getFiatSwitch');
  },
  // 查询PI升级状态
  getPiStatus() {
    return axios.get('@bapi/v3/kyc/common/status/v2');
  },
  // 提交PI升级请求
  submitUpgrade(data: object) {
    return axios.post('@bapi/v3/kyc/common/upgrade', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 查询用户身份信息
  getUserInfo(params: object = {}) {
    return axios.get('@bapi/v3/kyc/common/query/user_info', { params });
  },
  // 查询kyc国家配置信息
  queryCountryConfig(params: { configName: keyof KYC_COUNTRY_CONFIG_NAME }) {
    return axios.get('@bapi/v3/kyc/common/country/config',  { params });
  },
  // 个人PI转零售申请
  personalToRetailApply() {
    return axios.post('@bapi/v3/kyc/common/personalToRetailApply');
  },
  // 联系VIP团队
  submitConsult(data: object) {
    return axios.post('@bapi/v3/kyc/common/customer/pi/consult', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 16国银行认证
  confirmBank() {
    return axios.post('@bapi/v3/kyc/common/save/confirmBank');
  },
  // jumio 错误信息收集
  saveErrorLink(data: object) {
    return axios.post('@bapi/v3/kyc/jumio/errorLink/save', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 切线下
  changeOpenChannel(data: object) {
    return axios.post('@bapi/v3/kyc/common/change/open_channel', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 见证方式材料保存
  saveOfflineWitness(data: object) {
    return axios.post('@bapi/v3/kyc/common/save/kyc_material/gee_test', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 见证方式材料查询
  queryOfflineWitness(params: object) {
    return axios.get('@bapi/v3/kyc/common/query/kyc_material/offlineWitness', { params });
  },
  // 收件地址查询
  addressList() {
    return axios.get('@bapi/v3/kyc/common/query/delivery_address');
  },
  // 预约地点查询
  bookingPlaceList(params: object) {
    return axios.get('@bapi/v3/kyc/common/query/appointment_address', { params });
  },
  // 线下pdf发送
  sendPdf(data: object) {
    return axios.post('@bapi/v3/kyc/common/offline/pdf/send', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 线下pdf发送列表
  getPdfList(params: object) {
    return axios.get('@bapi/v3/kyc/common/offline/pdf/list', { params });
  },
  // 线下认证-见证信息提交的邮箱/手机号回显(不脱敏)
  getOfflineInfo() {
    return axios.get('@bapi/v3/kyc/common/offline/witness/userInfo');
  },
  // 区分用户国籍=支持三方认证的国家/地区
  getWhitelistcountry() {
    return axios.get('@bapi/v3/kyc/common/query/offlinewitness/whitelistcountry');
  },
  // 见证方式材料查询901新
  getOfflineWitnessWay(params: object) {
    return axios.get('@bapi/v3/kyc/common/query/offline_witness_way', { params });
  },
  // 开通hashkey custody
  openCustodyApply() {
    return axios.post('@bapi/v3/kyc/common/open/custody/apply');
  },
  // 获取OAuth Code
  getOauthToken(data: object) {
    return axios.post('@bapi/v3/user/common/oauthCode', data);
  },
  // 获取sumsub token
  getSumsubToken(data: object) {
    return axios.post('@bapi/v3/kyc/sumsub/token', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 4142 获取sumsub更新信息用的token
  /*
    "terminal" : 1, // 申请token的终端 1-web, 2-app
    "columns" : [1,2,3] // 1: 英文姓名，2:本国姓名，3:出生日期
  */
  getUpdateSumsubToken(data: object) {
    return axios.post('@bapi/v3/kyc/update/sumsub/token', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 4142 获取支持更新kyc信息的国家列表
  queryUpdateConfig()  {
    return axios.get('@bapi/v3/kyc/update/config');
  },
  // 4142 获取更新结果
  // externalUserId 如果externalUserId 为空，默认查询最近一次的结果
  getUpdateResult()  {
    return axios.get('@bapi/v3/kyc/update/result');
  },
  // 获取sumsub ocr
  getSumsubOcr(params: object)  {
    return axios.get('@bapi/v3/kyc/sumsub/ocr', { params });
  },
  // kyc失败状态下的申诉请求
  postAppeal(data: object) {
    return axios.post('@bapi/v3/kyc/common/apply/appeal', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取poa状态
  // idAsPoaStatus: 是否支持身份证作为poa | 1 支持，其他表示不支持
  // idAsPoaCountries: 支持的国家二字码数组
  getPoaStatus() {
    return axios.get('@bapi/v3/kyc/common/poa/status');
  },
  // 获取申诉请求
  getAppeal(params: object) {
    return axios.get('@bapi/v3/kyc/common/apply/appeal', { params });
  }
};
